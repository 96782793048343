.yt-lite {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
}
.yt-lite:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 60px;
    padding-bottom: 50px;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:after {
    content: "";
    display: block;
    padding-bottom: var(--aspect-ratio);
}
.yt-lite > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.yt-lite > .lty-playbtn {
    width: 96px;
    height: 96px;
    background-color: #FFFFFF;
    z-index: 1;
    border-radius: 50px;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
}
.yt-lite:hover > .lty-playbtn {
    background-color: #FFFFFF;
    opacity: 1;
    border: 0;
}
.yt-lite > .lty-playbtn:before {
    content: "";
    border-color: transparent transparent transparent #273245;
    border-style: solid;
    border-width: 18px 0 18px 28px;
}
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}
.yt-lite.lyt-activated {
    cursor: unset;
}
.yt-lite.lyt-activated:before,
.yt-lite.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}