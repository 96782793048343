.swiper-button-prev, .swiper-button-next {
    top: 90%;
    width: 32px;
    height: 32px;
    color: #1A2333;
    font-weight: 700;
    outline: 0;
    position: absolute;
    z-index: 100000000;
    cursor: pointer;
}

.swiper-button-next :after {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.swiper-button-prev :after {
     position: relative;
     left: -1px;
}

.swiper-button-next :after {
     position: relative;
     left: 1px;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 50%;
    right: auto;
    border: solid #1A2333;
    height: 16px;
    width: 16px;
    margin-left: -32px;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 50%;
    left: auto;
    border: solid #1A2333;
    height: 16px;
    width: 16px;
    margin-right: -32px;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
    color: #1A2333;
}